import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage"; 

const firebaseConfig = {
    apiKey: "AIzaSyBgMnMPXRpNOUwbn55LkS_YTFt2x_c7UYA",
    authDomain: "agrita-c3fb9.firebaseapp.com",
    projectId: "agrita-c3fb9",
    storageBucket: "agrita-c3fb9.appspot.com",
    messagingSenderId: "574578227943",
    appId: "1:574578227943:web:cb80ecdf50eeb09f2b8042"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

