import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <section className="hero-section">
        <h1>We simplify your Data Management</h1>
        <p>AGRITA empowers farmers with solutions crafted from customer feedback, <br></br>
        enabling them to accomplish more in less time with greater efficiency.</p>
        <div className="cta-buttons">
          <a href="#get-started" className="cta-button">Get Started</a>
           {/* Activate when instruction video is ready */}
          {/* <a href="#how-it-works" className="cta-button secondary">How It Works</a> */}
        </div>
      </section>

      <section className="about-content">
        <div className="about-section">
          <img src="/images/farmer-data.jpg" alt="Our Mission" className="about-image" />
          <div className="about-text">
            <h2>Our Mission</h2>
            <p>Our mission is to give farmers more time and make their lives easier by providing innovative solutions that truly meet their needs. We develop and design our products by actively listening to farmers' feedback and input, ensuring every feature we create is tailored to enhance their daily operations. By putting farmers at the heart of our process, we aim to simplify their work, boost their productivity, and contribute to the overall success of their business.</p>
          </div>
        </div>

        <div className="about-section reverse-layout">
          <div className="about-text">
            <h2>Our Values</h2>
            <ul className="values-list">
              <li><i className="fas fa-check-circle"></i> Integrity</li>
              <li><i className="fas fa-check-circle"></i> Innovation</li>
              <li><i className="fas fa-check-circle"></i> Community</li>
              <li><i className="fas fa-check-circle"></i> Excellence</li>
              <li><i className="fas fa-check-circle"></i> Sustainability</li>
            </ul>
          </div>
          <img src="/images/values.jpg" alt="Our Values" className="about-image" />
        </div>

        <div className="about-section">
          <img src="/images/cages.jpg" alt="Our Story" className="about-image" />
          <div className="about-text">
            <h2>Our Story</h2>
            <p>Agrita started in 2020 as an initiative to make the lives of farmers easier by providing them with a platform to efficiently manage their data. Driven by a deep love for agriculture and an understanding of its vital role in our country, we combined this passion with our knowledge in the tech space to create a solution that meets the needs of farmers. Agrita reflects our dedication to both agriculture and technology, and it continues to evolve, inspired by the feedback and support of the farming community.</p>
          </div>
        </div>
      </section>

      
    </div>
  );
};

export default About;
