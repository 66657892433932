import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import ClientHeader from '../../components/ClientHeader';
import { auth, db, storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import imageCompression from 'browser-image-compression'; 
import './Profile.css';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [country, setCountry] = useState('');
  const [profilePic, setProfilePic] = useState('/images/default-profile.png');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For upload progress indicator

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const data = userSnapshot.data();
          setUserData(data);
          setName(data.name);
          setSurname(data.surname);
          setEmail(data.email);
          setOrganisation(data.organisation);
          setCountry(data.country);
          setProfilePic(data.profilePic || '/images/default-profile.png');
        }
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, {
        name,
        surname,
        email,
        organisation,
        country,
        profilePic,
      });
      setEditMode(false);
    }
  };

  const handleProfilePicChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      setUploadProgress(0);
      try {
        // Image Compression
        const options = {
          maxSizeMB: 3,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);

        const user = auth.currentUser;
        if (user) {
          const storageRef = ref(storage, `profile_pics/${user.uid}`);
          const uploadTask = uploadBytesResumable(storageRef, compressedFile);

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            (error) => {
              console.error('Error uploading profile picture: ', error);
              setUploading(false);
            },
            async () => {
              const photoURL = await getDownloadURL(uploadTask.snapshot.ref);
              setProfilePic(photoURL);
              setUploading(false);
            }
          );
        }
      } catch (error) {
        console.error('Error compressing or uploading profile picture: ', error);
        setUploading(false);
      }
    }
  };

  return (
    <div className="profile-container">
      <ClientHeader />
      <Sidebar />
      <div className="profile-content">
        <div className="profile-header">
          <h1>Welcome, {name || 'User'}</h1>
          <p>{new Date().toLocaleDateString()}</p>
        </div>
        <div className="profile-main">
          <div className="profile-card">
            <div className="profile-pic-section">
              <img src={profilePic} alt="Profile" className="profile-pic" />
              {editMode && (
                <div>
                  <label className="choose-image-button">
                    Choose Image
                    <input type="file" accept="image/*" onChange={handleProfilePicChange} />
                  </label>
                  {uploading && <p className="uploading">Uploading: {Math.round(uploadProgress)}%</p>}
                </div>
              )}
              <h2>{name} {surname}</h2>
              <p>{email}</p>
            </div>
            <div className="profile-info-section">
              <div className="profile-fields">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Your First Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group">
                  <label>Surname</label>
                  <input
                    type="text"
                    placeholder="Your Last Name"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group">
                  <label>Organisation</label>
                  <input
                    type="text"
                    placeholder="Your Organisation's Name"
                    value={organisation}
                    onChange={(e) => setOrganisation(e.target.value)}
                    disabled={!editMode}
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    placeholder="Your Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    disabled={!editMode}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="profile-actions">
            {editMode ? (
              <>
                <button className="save-button" onClick={handleSave}>Save</button>
                <button className="cancel-button" onClick={() => setEditMode(false)}>Cancel</button>
              </>
            ) : (
              <button className="edit-button" onClick={() => setEditMode(true)}>Edit</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
