import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../components/Sidebar';
import { auth, db } from '../../firebase';
import { collection, query, orderBy, limit, getDocs, startAfter, where } from 'firebase/firestore';
import './Birds.css';
import ClientHeader from '../../components/ClientHeader';

const Birds = () => {
  const [birds, setBirds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredBirds, setFilteredBirds] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const birdsPerPage = 20;

  const fetchTotalBirdsCount = useCallback(async () => {
    const user = auth.currentUser;
      if (user) {
        const birdsCollection = collection(db, `users/${user.uid}/birds`);
        const totalBirdsQuery = query(birdsCollection);
        const totalBirdsSnapshot = await getDocs(totalBirdsQuery);
        const totalBirdsCount = totalBirdsSnapshot.size;
        setTotalPages(Math.ceil(totalBirdsCount / birdsPerPage));
      }
  }, [birdsPerPage]);

  const fetchBirds = useCallback(async (page = 1) => {
    const user = auth.currentUser;
      if (user) {
        const birdsCollection = collection(db, `users/${user.uid}/birds`);
        let birdsQuery = query(birdsCollection, orderBy('id'), limit(birdsPerPage));
  
        if (page > 1) {
          const previousPageQuery = query(birdsCollection, orderBy('id'), limit((page - 1) * birdsPerPage));
          const previousPageSnapshot = await getDocs(previousPageQuery);
          const lastVisible = previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];
          birdsQuery = query(birdsCollection, orderBy('id'), startAfter(lastVisible), limit(birdsPerPage));
        }

      const birdsSnapshot = await getDocs(birdsQuery);
      const birdsList = birdsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setBirds(birdsList);
      setFilteredBirds(birdsList);
    }
  }, [birdsPerPage]);

  useEffect(() => {
    fetchTotalBirdsCount();
    fetchBirds(1);
  }, [fetchTotalBirdsCount, fetchBirds]);

  const handleSearch = async (e) => {
    e.preventDefault();
      if (searchTerm.trim() !== '') {
        const user = auth.currentUser;
        if (user) {
          const birdsCollection = collection(db, `users/${user.uid}/birds`);
          const birdsQuery = query(birdsCollection, where('id', '==', searchTerm));
          const birdsSnapshot = await getDocs(birdsQuery);
          const birdsList = birdsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setFilteredBirds(birdsList);
        }
      }
      else {
        setFilteredBirds(birds);
      }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchBirds(newPage);
  };

  return (
    <div className="birds-container">
      <ClientHeader /> 
      <Sidebar />
      <div className="birds-content">
        <h1>Birds Management</h1>
        <p>Here you can manage all your birds.</p>
        
        <div className="bird-search">
          <form onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="Search by Bird ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button type="submit">Search</button>
          </form>
          {/* Add new bird button should open up a new page/form for the user to capture bird's details. */}
          {/* <button type="submit">Add New Bird</button> */}
        </div>

        <div className="bird-table">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Cage ID</th>
                <th>Species</th>
                <th>Age</th>
                <th>Sex</th>
                <th>Status</th>
                <th>Origin</th>
                <th>Origin Detail</th>
                <th>ID Type</th>
                <th>Is Sick</th>
                <th>First Breeding Year</th>
                <th>Total Chicks Bred</th>
              </tr>
            </thead>
            <tbody>
              {filteredBirds.length > 0 ? (
                filteredBirds.map((bird, index) => (
                  <tr key={index}>
                    <td>{bird.id}</td>
                    <td>{bird.cageId}</td>
                    <td>{bird.species}</td>
                    <td>{bird.age}</td>
                    <td>{bird.sex}</td>
                    <td>{bird.status}</td>
                    <td>{bird.origin}</td>
                    <td>{bird.originDetail}</td>
                    <td>{bird.idType}</td>
                    <td>{bird.isSick ? 'Yes' : 'No'}</td>
                    <td>{bird.firstBreedingYear}</td>
                    <td>{bird.totalChicksBred}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12">No birds found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`pagination-button ${page === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Birds;
