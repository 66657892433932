import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import './ClientHeader.css';

const ClientHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } 
    catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <header className="client-header">
      <div className="client-header-content">
        <div className="client-header-left">
          <button className="sidebar-toggle" onClick={toggleMenu} aria-label="Toggle Navigation">
            ☰
          </button>
          <img src="/images/logo.png" alt="Agrita Logo" className="client-header-logo" />
        </div>
        <div className="client-header-right">
          <input type="text" placeholder="Search..." className="client-header-search" aria-label="Search" />
          <div className="client-header-icons">
            <Link to="/profile" aria-label="User Profile">
              <i className="fas fa-user" aria-hidden="true"></i>
            </Link>
          </div>
        </div>
      </div>

      {/* // Nav menu, dropdown on mobile */}
      {menuOpen && (
        <nav className="client-mobile-nav">
          <ul>
            <li><Link to="/dashboard" onClick={() => setMenuOpen(false)}>Dashboard</Link></li>
            <li><Link to="/birds" onClick={() => setMenuOpen(false)}>Birds</Link></li>
            <li><Link to="/cages" onClick={() => setMenuOpen(false)}>Cages</Link></li>
            <li><Link to="/eggs" onClick={() => setMenuOpen(false)}>Eggs Management</Link></li>
            <li><Link to="/chicks" onClick={() => setMenuOpen(false)}>Chicks</Link></li>
            <li><Link to="/profile" onClick={() => setMenuOpen(false)}>Profile</Link></li>
            <li><button className="client-logout-button" onClick={() => { handleLogout(); setMenuOpen(false); }}>Logout</button></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default ClientHeader;
