import React, { useState } from 'react';
import emailjs from 'emailjs-com'; 
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS service configuration
    // .env file? 
    const serviceID = 'service_pi8rl1k'; 
    const templateID = 'template_187f76f'; 
    const userID = '6yGynKxMtxxVhwlAh'; 

    emailjs.sendForm(serviceID, templateID, e.target, userID)
      .then((result) => {
        console.log(result.text);
        setStatusMessage('Message sent successfully!');
        setShowPopup(true); // Show popup on successful message send
        setName('');
        setEmail('');
        setMessage('');
      }, (error) => {
        console.log(error.text);
        setStatusMessage('Failed to send message. Please try again.');
      });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="contact-container">
      <header className="contact-header">
        <h1>Contact Us</h1>
        <p>We would love to hear from you! Reach out with any questions or feedback.</p>
      </header>
      <section className="contact-content">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <div className="input-icon">
              <i className="fas fa-user"></i>
              <input 
                type="text" 
                id="name" 
                name="user_name"
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                required 
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <div className="input-icon">
              <i className="fas fa-envelope"></i>
              <input 
                type="email" 
                id="email" 
                name="user_email"
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <div className="input-icon">
              <i className="fas fa-comment-dots"></i>
              <textarea 
                id="message" 
                name="message"
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
                required 
              ></textarea>
            </div>
          </div>
          <button type="submit">Send Message</button>
        </form>
        {showPopup && (
          <div className="popup-box">
            <div className="popup-content">
              <p>{statusMessage}</p>
              <button onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
        <div className="contact-details">
          <h2>Our Contact Details</h2>
          <p><i className="fas fa-phone"></i> Phone: +27 76 318 4184</p>
          <p><i className="fas fa-envelope"></i> Email: support@agrita.co.za</p>
        </div>
      </section>
    </div>
  );
};

export default Contact;