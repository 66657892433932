import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-logo">
        <Link to="/">
          <img src="/images/AGRITA_logo_white.png" alt="Agrita Logo" />
        </Link>
      </div>
      <div className="footer-links">
        <ul>
          <li>
            <a href="/pdfs/Terms-and-Conditions.pdf" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>
          </li>
          <li>
            <a href="/pdfs/Terms-of-Use.pdf" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>
          </li>
          <li>
            <a href="/pdfs/Privacy-Policy.pdf" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-social">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <i className="fab fa-linkedin-in"></i>
        </a>
      </div>
      <div className="footer-download">
        <h3>Get the App</h3>
        <div className="download-links">
          <a href="https://play.google.com/store/apps/details?id=com.example.app" target="_blank" rel="noopener noreferrer" className="google-play">
            <img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"/>
          </a>
          <a href="https://apps.apple.com/us/app/example-app/id123456789" target="_blank" rel="noopener noreferrer" className="app-store">
            <img alt="Download on the App Store" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=2021-09-01T00:00:00Z&amp;h=89c1e25d1d7d3c8c937fbc9c81b1c2a5"/>
          </a>
        </div>
      </div>
      <div className="footer-contact">
        <p>Email: <a href="mailto:support@agrita.co.za">support@agrita.co.za</a></p>
        <p>Phone: <a href="tel:+1234567890">+123 456 7890</a></p>
      </div>
    </footer>
  );
};

export default Footer;
