import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/client/Dashboard';
import Birds from './pages/client/Birds';
import Cages from './pages/client/Cages';
import Profile from './pages/client/Profile';
import { AuthProvider } from './contexts/AuthContext';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Eggs from './pages/client/Eggs'; 
import Chicks from './pages/client/Chicks'; 


const AppContent = () => {
  const location = useLocation();
  const clientRoutes = ['/dashboard', '/birds', '/cages','/eggs', '/chicks', '/profile'];

  const isClientRoute = clientRoutes.some(route => location.pathname.startsWith(route));

  return (
    <div>
      {!isClientRoute && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/birds" element={<Birds />} />
        <Route path="/cages" element={<Cages />} />
        <Route path="/eggs" element={<Eggs />} />
        <Route path="/chicks" element={<Chicks />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
      {!isClientRoute && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
