import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../components/Sidebar';
import ClientHeader from '../../components/ClientHeader';
import { auth, db } from '../../firebase';
import { collection, query, orderBy, limit, getDocs, startAfter, doc, writeBatch, increment } from 'firebase/firestore';
import './Eggs.css';

const Eggs = () => {
  const [eggs, setEggs] = useState([]);
  const [selectedEggs, setSelectedEggs] = useState([]); // To track selected eggs
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const eggsPerPage = 20;

  const fetchTotalEggsCount = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const eggsCollection = collection(db, `users/${user.uid}/eggs`);
      const totalEggsSnapshot = await getDocs(eggsCollection);
      const totalEggsCount = totalEggsSnapshot.size;
      setTotalPages(Math.ceil(totalEggsCount / eggsPerPage));
    }
  }, [eggsPerPage]);

  const fetchEggs = useCallback(async (page = 1) => {
    const user = auth.currentUser;
    if (user) {
      const eggsCollection = collection(db, `users/${user.uid}/eggs`);
      let eggsQuery = query(eggsCollection, orderBy('eggId'), limit(eggsPerPage)); // Use 'eggId' for ordering

      if (page > 1) {
        const previousPageQuery = query(eggsCollection, orderBy('eggId'), limit((page - 1) * eggsPerPage));
        const previousPageSnapshot = await getDocs(previousPageQuery);
        const lastVisible = previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];
        eggsQuery = query(eggsCollection, orderBy('eggId'), startAfter(lastVisible), limit(eggsPerPage));
      }

      const eggsSnapshot = await getDocs(eggsQuery);
      const eggsList = eggsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          eggId: data.eggId || 'N/A', // Ensure 'eggId' exists
          cageId: data.cageId || 'N/A',
          fertile: data.isFertile !== undefined ? data.isFertile : 'Unknown', // Handle undefined fertile status
          status: data.status || 'Pending',
          originBirds: data.originBirds ? data.originBirds.join(', ') : 'Unknown',
          dateMovedToBreeding: data.dateMovedToBreeding ? new Date(data.dateMovedToBreeding).toLocaleDateString() : 'N/A',
          dateHatched: data.dateHatched ? new Date(data.dateHatched).toLocaleDateString() : 'N/A',
        };
      });

      setEggs(eggsList);
    }
  }, [eggsPerPage]);

  useEffect(() => {
    fetchTotalEggsCount();
    fetchEggs(1);
  }, [fetchTotalEggsCount, fetchEggs]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchEggs(newPage);
  };

  // Handle selection of eggs
  const handleSelectEgg = (eggId) => {
    setSelectedEggs((prevSelected) =>
      prevSelected.includes(eggId)
        ? prevSelected.filter((id) => id !== eggId)
        : [...prevSelected, eggId]
    );
  };

  // Move selected eggs to chicks collection and update statistics
  const handleMoveToChicks = async () => {
    const user = auth.currentUser;
    if (user && selectedEggs.length > 0) {
      const batch = writeBatch(db);
      const statsRef = doc(db, `users/${user.uid}/eggStats/stats`);

      selectedEggs.forEach((eggId) => {
        const eggData = eggs.find((egg) => egg.id === eggId);
        const chickRef = doc(db, `users/${user.uid}/chicks/${eggId}`); // Use eggId as chickId
        const eggRef = doc(db, `users/${user.uid}/eggs/${eggId}`);

        batch.set(chickRef, {
          id: eggId, // Use eggId as chickId
          sex: eggData.sex || '', // If sex is known at this point
          cageOrigin: eggData.cageId,
          health: 'Healthy', // Default
          status: 'Active', // Defeault
        });

        batch.delete(eggRef); // Remove egg from eggs collection

        // Update egg stats
        if (eggData.fertile) {
          batch.update(statsRef, { fertileEggs: increment(1) });
        } else {
          batch.update(statsRef, { infertileEggs: increment(1) });
        }
      });

      await batch.commit();
      alert('Selected eggs have been moved to the Chicks table and stats updated!');
      setSelectedEggs([]); // Clear selection
      fetchEggs(page); // Refresh the list of eggs
    }
  };

  return (
    <div className="eggs-container">
      <ClientHeader />
      <Sidebar />
      <div className="eggs-content">
        <h1>Eggs Management</h1>
        <p>Here you can manage all the eggs in your system.</p>

        <div className="actions">
          <button
            className="move-to-chicks-button"
            onClick={handleMoveToChicks}
            disabled={selectedEggs.length === 0}
          >
            Move Selected Eggs to Chicks
          </button>
        </div>

        <div className="egg-table">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th>Egg ID</th>
                <th>Cage ID</th>
                <th>Fertility</th>
                <th>Status</th>
                <th>Origin Birds</th>
                <th>Date Moved to Breeding Room</th>
                <th>Date Hatched</th>
              </tr>
            </thead>
            <tbody>
              {eggs.length > 0 ? (
                eggs.map((egg, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedEggs.includes(egg.id)}
                        onChange={() => handleSelectEgg(egg.id)}
                      />
                    </td>
                    <td>{egg.eggId}</td>
                    <td>{egg.cageId}</td>
                    <td>{egg.fertile ? 'Yes' : 'No'}</td>
                    <td>{egg.status}</td>
                    <td>{egg.originBirds}</td>
                    <td>{egg.dateMovedToBreeding}</td>
                    <td>{egg.dateHatched}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No eggs found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`pagination-button ${page === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Eggs;
