import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <div className="hero-content">
          <h1>Welcome to Agrita</h1>
          <p>Your go-to platform for your Livestock data management needs</p>
          <a href="#features" className="cta-button">Get Started</a>
        </div>
      </header>
      
      <section id="features" className="home-features">
        <div className="feature">
          <i className="fas fa-check-circle feature-icon"></i>
          <h2>Easy to Use</h2>
          <p>Our platform is intuitive and easy to use. Try our free 14-day trial now!</p>
        </div>
        <div className="feature">
          <i className="fas fa-heartbeat feature-icon"></i>
          <h2>Expert Care Tips</h2>
          <p>Get professional advice from industry-leading experts on caring for your livestock.</p>
        </div>
        <div className="feature">
          <i className="fas fa-users feature-icon"></i>
          <h2>Community Support</h2>
          <p>Join our community and share your experiences with other industry enthusiasts.</p>
        </div>
        <div className="feature">
          <i className="fas fa-tachometer-alt feature-icon"></i>
          <h2>Improve Efficiency</h2>
          <p>Optimize your farm operations with our tools designed to increase productivity. Get more done, in less time!</p>
        </div>
        <div className="feature">
          <i className="fas fa-database feature-icon"></i>
          <h2>Simplified Data Management</h2>
          <p>Easily manage all your farm data in one place with our user-friendly interface.</p>
        </div>
        <div className="feature">
          <i className="fas fa-tractor feature-icon"></i>
          <h2>Developed by Farmers, for Farmers</h2>
          <p>Our platform is built and designed by farmers who understand your needs and challenges.</p>
        </div>
      </section>


      {/* // Testimonials */}
      <section className="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonial-cards">
          <div className="testimonial">
            <p>"Agrita has transformed the way I manage my bird breeding data!"</p>
            <h3>- Marko Grobler</h3>
          </div>
          <div className="testimonial">
            <p>"The community support is unmatched, I've learned so much."</p>
            <h3>- Jessica Leicester</h3>
          </div>
          <div className="testimonial">
            <p>"I can't imagine going back to paper records, this is the future!"</p>
            <h3>- Dewaldt van der Merwe</h3>
          </div>
        </div>
      </section>

      {/* // Newsletter sign up card */}
      {/* Link to crm? */}
      <section className="newsletter">
        <h2>Stay Updated</h2>
        <p>Subscribe to our newsletter to get the latest updates and tips.</p>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button type="submit">Subscribe</button>
        </form>
      </section>
    </div>
  );
};

export default Home;
