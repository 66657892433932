import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar';
import ClientHeader from '../../components/ClientHeader';
import { auth, db } from '../../firebase';
import { doc, getDoc, collection, getDocs, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import './Dashboard.css';
import '../../components/Sidebar.css'; 

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [chicksData, setChicksData] = useState([]);
  const [eggsData, setEggsData] = useState([]);
  const [healthData, setHealthData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [summary, setSummary] = useState({
    totalBirds: 0,
    totalCages: 0,
    totalChicks: 0,
    totalEggs: 0,
    totalDeaths: 0,
  });

  const COLORS = ['#84d89a', '#f54538'];


  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          setUserData(userSnapshot.data());
        }
      }
    };

     // Task table
    const fetchTasks = async () => {
      const user = auth.currentUser;
      if (user) {
        const tasksCollection = collection(db, `users/${user.uid}/tasks`);
        const tasksSnapshot = await getDocs(tasksCollection);
        const tasksList = tasksSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTasks(tasksList);
      }
    };

          const fetchBirdsData = async () => {
            const user = auth.currentUser;
            if (user) {
              // Fetch birds data
              const birdsCollection = collection(db, `users/${user.uid}/birds`);
              const birdsSnapshot = await getDocs(birdsCollection);
          
              // Fetch cages data
              const cagesCollection = collection(db, `users/${user.uid}/cages`);
              const cagesSnapshot = await getDocs(cagesCollection);
          
              let chicksByYear = {};
              let eggsByYear = {};
              let healthy = 0;
              let sick = 0;
              let totalBirds = 0;
              let totalDeaths = 0;
              let totalEggs = 0;
          
              // Process birds data
              birdsSnapshot.docs.forEach(doc => {
                const bird = doc.data();
                totalBirds += 1;
                const year = bird.firstBreedingYear;
          
                if (chicksByYear[year]) {
                  chicksByYear[year] += bird.totalChicksBred;
                } else {
                  chicksByYear[year] = bird.totalChicksBred;
                }
          
                if (bird.isSick) {
                  sick += 1;
                } else {
                  healthy += 1;
                }
          
                if (bird.status === 'Deceased') {
                  totalDeaths += 1;
                }
              });
          
              // Process cages data
              cagesSnapshot.docs.forEach(doc => {
                const cage = doc.data();
          
                // Sum up fatalities
                if (cage.fatalities) {
                  totalDeaths += cage.fatalities;
                }
          
                // Sum up eggs
                if (typeof cage.eggs === 'number') {
                  totalEggs += cage.eggs;
          
                  const eggsYear = new Date(cage.lastEggLaid).getFullYear();
                  if (eggsByYear[eggsYear]) {
                    eggsByYear[eggsYear] += cage.eggs;
                  } else {
                    eggsByYear[eggsYear] = cage.eggs;
                  }
                }
              });
          
              const chicksData = Object.keys(chicksByYear).map(year => ({
                name: year,
                chicks: chicksByYear[year],
              })).sort((a, b) => a.name - b.name);
          
              // const eggsData = Object.keys(eggsByYear).map(year => ({
              //   name: year,
              //   eggs: eggsByYear[year],
              // })).sort((a, b) => a.name - b.name);
          
              setChicksData(chicksData);
              // setEggsData(eggsData);
              setHealthData([
                { name: 'Healthy', value: healthy },
                { name: 'Sick', value: sick },
              ]);
          
              setSummary(prev => ({
                ...prev,
                totalBirds,
                totalChicks: Object.values(chicksByYear).reduce((a, b) => a + b, 0),
                totalEggs,
                totalDeaths,
              }));
            }
          };
          
          const fetchEggsData = async () => {
            const user = auth.currentUser;
            if (user) {
              const statsCollection = collection(db, `users/${user.uid}/statistics`);
              const statsSnapshot = await getDocs(statsCollection);
        
              let eggsData = [];
        
              statsSnapshot.docs.forEach(doc => {
                const stats = doc.data();
                const year = stats.year; // Ensure you have a 'year' field or adjust accordingly
                const fertileEggs = stats.fertileEggs || 0;
                const totalEggs = stats.totalEggs || 0;
                const infertileEggs = totalEggs - fertileEggs;
        
                eggsData.push({
                  name: year,
                  fertileEggs,
                  infertileEggs,
                });
              });
        
              setEggsData(eggsData);
            }
          };       
          

    const fetchCagesData = async () => {
      const user = auth.currentUser;
        if (user) {
          const cagesCollection = collection(db, `users/${user.uid}/cages`);
          const cagesSnapshot = await getDocs(cagesCollection);
          setSummary(prev => ({
            ...prev,
            totalCages: cagesSnapshot.size,
          }));
        }
    };

    fetchUserData();
    fetchBirdsData();
    fetchCagesData();
    fetchEggsData();
    fetchTasks();
    removeOldCompletedTasks();
  }, []);

  const handleAddTask = async () => {
    if (newTask.trim() !== '') {
      const user = auth.currentUser;
      if (user) {
        const tasksCollection = collection(db, `users/${user.uid}/tasks`);
        const task = {
          description: newTask,
          completed: false,
        };
        await addDoc(tasksCollection, task);
        setNewTask('');
        fetchTasks();
      }
    }
  };

  const handleCompleteTask = async (taskId) => {
    const user = auth.currentUser;
      if (user) {
        const taskDoc = doc(db, `users/${user.uid}/tasks/${taskId}`);
        await updateDoc(taskDoc, {
          completed: true,
          completedAt: new Date(),
        });
        fetchTasks();
      }
  };

  const removeOldCompletedTasks = async () => {
    const user = auth.currentUser;
      if (user) {
        const tasksCollection = collection(db, `users/${user.uid}/tasks`);
        const tasksSnapshot = await getDocs(tasksCollection);
  
        const now = new Date();
        tasksSnapshot.docs.forEach(async (docSnapshot) => {
          const task = docSnapshot.data();
            if (task.completed && task.completedAt) {
              const completedDate = task.completedAt.toDate();
              const daysDifference = Math.floor((now - completedDate) / (1000 * 60 * 60 * 24));
    
                if (daysDifference > 7) {
                  await deleteDoc(doc(db, `users/${user.uid}/tasks/${docSnapshot.id}`));
                }
          }
        });
  
        fetchTasks();
      }
  };

  return (
    <div className="client-dashboard-container">
      <Sidebar isOpen={true} /> 
      <div className="client-dashboard-content">
        <ClientHeader />
        {userData && (
          <div className="client-user-info">
            <h2>Welcome, {userData.name} {userData.surname}</h2>
            <p>Email: {userData.email}</p>
          </div>
        )}

        {/* // Metrics summary */}
        <div className="client-metrics-summary">
          <div className="client-metric-card">
            <h4>Total Birds</h4>
            <p>{summary.totalBirds}</p>
          </div>
          <div className="client-metric-card">
            <h4>Total Cages</h4>
            <p>{summary.totalCages}</p>
          </div>
          <div className="client-metric-card">
            <h4>Total Chicks Bred</h4>
            <p>{summary.totalChicks}</p>
          </div>
          <div className="client-metric-card">
            <h4>Total Eggs</h4>
            <p>{summary.totalEggs}</p>
          </div>
          <div className="client-metric-card">
            <h4>Total Deaths</h4>
            <p>{summary.totalDeaths}</p>
          </div>
        </div>

        <div className="client-charts-section">
          <h3>Your Bird Data Overview</h3>
          <div className="client-charts-container">
            <div className="client-chart-item">
              <h4>Chicks Bred Over Time</h4>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chicksData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Tooltip />
                  <Line type="monotone" dataKey="chicks" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
                    {/* <div className="client-chart-item">
                      <h4>Eggs Collected Annually</h4>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={eggsData}>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                          <Tooltip />
                          <Bar dataKey="eggs" fill="#82ca9d" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div> */}

          <div className="client-chart-item">
            <h4>Fertile vs Infertile Eggs Annually</h4>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={eggsData}>
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="fertileEggs" fill="#82ca9d" name="Fertile Eggs" />
                <Bar dataKey="infertileEggs" fill="#ff7300" name="Infertile Eggs" />
              </BarChart>
            </ResponsiveContainer>
          </div>

            <div className="client-chart-item">
              <h4>Bird Health Status</h4>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie data={healthData} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#84d89a">
                    {healthData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* // Upcomming tasks */}
        <div className="client-tasks-section">
          <h3>Upcoming Tasks</h3>
          <div className="client-task-input">
            <input
              type="text"
              placeholder="Add a new task..."
              value={newTask}
              onChange={(e) => setNewTask(e.target.value)}
              aria-label="Add a new task"
            />
            <button onClick={handleAddTask}>Add Task</button>
          </div>
          <table className="client-tasks-table">
            <thead>
              <tr>
                <th>Task</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(task => (
                <tr key={task.id} className={task.completed ? 'client-completed' : 'client-pending'}>
                  <td>{task.description}</td>
                  <td className={`status ${task.completed ? 'client-completed' : 'client-pending'}`}>
                    {task.completed ? 'Completed' : 'Pending'}
                  </td>
                  <td>
                    {!task.completed && (
                      <button onClick={() => handleCompleteTask(task.id)}>Complete</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;




