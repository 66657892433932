import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../components/Sidebar';
import ClientHeader from '../../components/ClientHeader';
import { auth, db } from '../../firebase';
import { collection, query, orderBy, limit, getDocs, startAfter, doc, writeBatch, updateDoc, increment, getDoc } from 'firebase/firestore';
import './Chicks.css';

const Chicks = () => {
  const [chicks, setChicks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [editMode, setEditMode] = useState(null); // Track which chick is in edit mode
  const [editedChick, setEditedChick] = useState({}); // Track the edited chick data
  const chicksPerPage = 20;

  const fetchTotalChicksCount = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const chicksCollection = collection(db, `users/${user.uid}/chicks`);
      const totalChicksSnapshot = await getDocs(chicksCollection);
      const totalChicksCount = totalChicksSnapshot.size;
      setTotalPages(Math.ceil(totalChicksCount / chicksPerPage));
    }
  }, [chicksPerPage]);

  const fetchChicks = useCallback(async (page = 1) => {
    const user = auth.currentUser;
    if (user) {
      const chicksCollection = collection(db, `users/${user.uid}/chicks`);
      let chicksQuery = query(chicksCollection, orderBy('id'), limit(chicksPerPage));
  
      if (page > 1) {
        const previousPageQuery = query(chicksCollection, orderBy('id'), limit((page - 1) * chicksPerPage));
        const previousPageSnapshot = await getDocs(previousPageQuery);
        const lastVisible = previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];
        chicksQuery = query(chicksCollection, orderBy('id'), startAfter(lastVisible), limit(chicksPerPage));
      }
  
      const chicksSnapshot = await getDocs(chicksQuery);
      const chicksList = chicksSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        showInspectionHistory: false, 
      }));
  
      setChicks(chicksList);
    }
  }, [chicksPerPage]);
  

  useEffect(() => {
    fetchTotalChicksCount();
    fetchChicks(1);
  }, [fetchTotalChicksCount, fetchChicks]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchChicks(newPage);
  };

  const handleEditClick = (chickId) => {
    setEditMode(chickId);
    
    const chickData = chicks.find((chick) => chick.id === chickId);
    setEditedChick(chickData);
  };

  const handleInputChange = (field, value) => {
    setEditedChick((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveClick = async () => {
    const user = auth.currentUser;
      if (user && editMode) {
        const chickRef = doc(db, `users/${user.uid}/chicks/${editMode}`);
        await updateDoc(chickRef, editedChick);
        setEditMode(null);
        fetchChicks(page);
      }
  };

  const handleSellChick = async (chickId) => {
    const user = auth.currentUser;
      if (user) {
        const batch = writeBatch(db);
        const chickRef = doc(db, `users/${user.uid}/chicks/${chickId}`);
        const statsRef = doc(db, `users/${user.uid}/statistics/chickStats`);

        batch.update(chickRef, { status: 'Sold', soldDate: new Date() });
  
        const statsDoc = await getDoc(statsRef);
          if (statsDoc.exists()) {
            batch.update(statsRef, { totalSold: increment(1) });
          } 
          else {
            batch.set(statsRef, { totalSold: 1 });
          }
    
          await batch.commit();
          fetchChicks(page);
        }
  };

  const toggleInspectionHistory = (chickId) => {
    setChicks(prevChicks =>
      prevChicks.map(chick =>
        chick.id === chickId
          ? { ...chick, showInspectionHistory: !chick.showInspectionHistory }
          : chick
      )
    );
  };

  return (
    <div className="chicks-container">
      <ClientHeader />
      <Sidebar />
      <div className="chicks-content">
        <h1>Chicks Management</h1>
        <p>Here you can manage all the chicks in your system.</p>

        <div className="chick-table">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Sex</th>
                <th>Cage Origin</th>
                <th>Health</th>
                <th>Status</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {chicks.length > 0 ? (
                chicks.map((chick, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      {editMode === chick.id ? (
                        <>
                          <td><input type="text" value={editedChick.id} onChange={(e) => handleInputChange('id', e.target.value)} /></td>
                          <td><input type="text" value={editedChick.sex} onChange={(e) => handleInputChange('sex', e.target.value)} /></td>
                          <td><input type="text" value={editedChick.cageOrigin} onChange={(e) => handleInputChange('cageOrigin', e.target.value)} /></td>
                          <td><input type="text" value={editedChick.healthStatus} onChange={(e) => handleInputChange('health', e.target.value)} /></td>
                          <td><input type="text" value={editedChick.status} onChange={(e) => handleInputChange('status', e.target.value)} /></td>
                          <td><input type="text" value={editedChick.notes} onChange={(e) => handleInputChange('notes', e.target.value)} /></td>
                          <td>
                            <button className="save-button" onClick={handleSaveClick}>Save</button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{chick.id}</td>
                          <td>{chick.sex}</td>
                          <td>{chick.cageOrigin}</td>
                          <td>{chick.healthStatus}</td>
                          <td>{chick.status}</td>
                          <td>{chick.notes}</td>
                          <td>
                            <button className="edit-button" onClick={() => handleEditClick(chick.id)}>Edit</button>
                            <button className="sell-button" onClick={() => handleSellChick(chick.id)}>Sell</button>
                            <button onClick={() => toggleInspectionHistory(chick.id)}>View Inspections</button>
                          </td>
                        </>
                      )}
                    </tr>
                    {chick.showInspectionHistory && (
                      <tr className="inspection-history-row">
                        <td colSpan="6">
                          {chick.inspections.length > 0 ? (
                            <table className="inspection-table">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Health Status</th>
                                  <th>Notes</th>
                                </tr>
                              </thead>
                              <tbody>
                                {chick.inspections.map((inspection, i) => (
                                  <tr key={i}>
                                    <td>{new Date(inspection.date).toLocaleDateString()}</td>
                                    <td>{inspection.healthStatus}</td>
                                    <td>{inspection.notes}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <p>No inspections found for this chick.</p>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No chicks found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`pagination-button ${page === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Chicks;


