import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import './Signup.css';

const Signup = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [country, setCountry] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await setDoc(doc(db, 'users', user.uid), {
        name: name,
        surname: surname,
        email: user.email,
        organisation: organisation,
        country: country,
      });
      navigate('/dashboard');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="signup-container">
      <header className="signup-header">
        <h1>Sign Up</h1>
        <p>Create your account</p>
      </header>
      <section className="signup-content">
        <form className="signup-form" onSubmit={handleSignup}>
        <div className="form-group">
            <label htmlFor="organisation">Organisation Name</label>
            <input 
              type="text" 
              id="organisation" 
              value={organisation} 
              onChange={(e) => setOrganisation(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input 
              type="text" 
              id="country" 
              value={country} 
              onChange={(e) => setCountry(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              id="name" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Surname</label>
            <input 
              type="text" 
              id="surname" 
              value={surname} 
              onChange={(e) => setSurname(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              id="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          <button type="submit">Sign Up</button>
          <p className="login-link">
            Already have an account? <a href="/login">Log In</a>
          </p>
        </form>
      </section>
    </div>
  );
};

export default Signup;
