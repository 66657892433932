import React, { useState } from 'react';
import './Pricing.css';

const Pricing = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');

  const toggleBillingCycle = () => {
    setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
  };

  const getPrice = (monthly, yearly) => {
    const formatPrice = (price) => price.toLocaleString('en-ZA'); 
    return billingCycle === 'monthly' 
      ? `R${formatPrice(monthly)} /month` 
      : `R${formatPrice(yearly)} /year`;
  };
  

  return (
    <div className="pricing-container">
      <header className="pricing-header">
        <h1>Our Pricing Plans</h1>
        <p>Save 15% with our yearly plans!</p>
        <div className="billing-toggle">
          <span>Monthly</span>
          <div className="toggle-switch" onClick={toggleBillingCycle}>
            <div className={`toggle-thumb ${billingCycle}`}></div>
          </div>
          <span>Yearly</span>
        </div>
      </header>
      <section className="pricing-cards">
        <div className="pricing-card">
          <div className="badge">Limited Offer!</div>
          <h2>Basic Plan</h2>
          <p className="price">{getPrice(500, 5100)}</p>
          <ul>
            <li><i className="fas fa-check-circle"></i> 500 - 1000 Birds</li>
            <li><i className="fas fa-check-circle"></i> Task Management</li>
            <li><i className="fas fa-check-circle"></i> Data Dashboard</li>
          </ul>
          <button>Select Plan</button>
        </div>
        <div className="pricing-card popular-plan">
          <div className="badge-2">Most Popular</div>
          <h2>Standard Plan</h2>
          <p className="price">{getPrice(900, 9180)}</p>
          <ul>
            <li><i className="fas fa-check-circle"></i> 1000 - 2000 Birds</li>
            <li><i className="fas fa-check-circle"></i> Task Management</li>
            <li><i className="fas fa-check-circle"></i> Data Dashboard</li>
            <li><i className="fas fa-check-circle"></i> Add Team members</li>
            <li><i className="fas fa-check-circle"></i> Breeding performance</li>
            <li><i className="fas fa-check-circle"></i> Personalised onboarding</li>
          </ul>
          <button>Select Plan</button>
        </div>
        <div className="pricing-card">
          <div className="badge-3">Coming Soon!</div>
          <h2>Premium Plan</h2>
          <p className="price">{getPrice(1200, 12240)}</p>
          <ul>
            <li><i className="fas fa-check-circle"></i> 2000+ Birds</li>
            <li><i className="fas fa-check-circle"></i> Task Management</li>
            <li><i className="fas fa-check-circle"></i> Data Dashboard</li>
            <li><i className="fas fa-check-circle"></i> Add Team members</li>
            <li><i className="fas fa-check-circle"></i> Breeding performance</li>
            <li><i className="fas fa-check-circle"></i> QR Code Inspections</li>
            <li><i className="fas fa-check-circle"></i> Generate data reports</li>
            <li><i className="fas fa-check-circle"></i> Custom Data Fields</li>
            <li><i className="fas fa-check-circle"></i> Personalised onboarding</li>
          </ul>
          <button>Select Plan</button>
        </div>
      </section>
      <section className="comparison-table">
        <h2>Compare Features</h2>
        <table>
          <thead>
            <tr>
              <th>Feature</th>
              <th>Basic</th>
              <th>Standard</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Task Management</td>
              <td><i className="fas fa-check-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
            </tr>
            <tr>
              <td>Data Dashboard</td>
              <td><i className="fas fa-check-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
            </tr>
            <tr>
              <td>Add Team Members</td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
            </tr>
            <tr>
              <td>Breeding Performance</td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
            </tr>
            <tr>
              <td>Personalised Onboarding</td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
            </tr>
            <tr>
              <td>QR Code Inspections</td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
            </tr>
            <tr>
              <td>Generate Data Reports</td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
            </tr>
            <tr>
              <td>Custom Data Fields</td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-times-circle"></i></td>
              <td><i className="fas fa-check-circle"></i></td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="cta-banner">
        <h2>Need help deciding?</h2>
        <p>Contact us for personalized recommendations.</p>
        <button>Contact Us</button>
      </section>
    </div>
  );
};

export default Pricing;


