import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import './Sidebar.css';

const Sidebar = ({ isOpen }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <div className={`client-sidebar ${isOpen ? '' : 'client-sidebar-collapsed'}`}>
      <nav className="client-sidebar-nav">
        <ul>
          <li><Link to="/dashboard">Dashboard</Link></li>
          <li><Link to="/birds">Birds</Link></li>
          <li><Link to="/cages">Cages</Link></li>
          <li><Link to="/eggs">Eggs Management</Link></li>
          <li><Link to="/chicks">Chicks</Link></li>
          <li><Link to="/profile">Profile</Link></li>
          <li><button className="client-logout-button" onClick={handleLogout}>Logout</button></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
