import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { FiMenu, FiX } from 'react-icons/fi';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header-nav ${menuOpen ? 'open' : ''}`}> 
      <div className="main-logo"> 
        <Link to="/" onClick={() => setMenuOpen(false)}>
          <img src="/images/AGRITA_logo_white.png" alt="Agrita Logo" />
        </Link>
      </div>
      <nav className="nav-menu">
        <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
          <li><Link to="/about" onClick={() => setMenuOpen(false)}>About</Link></li>
          <li><Link to="/contact" onClick={() => setMenuOpen(false)}>Contact</Link></li>
          <li><Link to="/pricing" onClick={() => setMenuOpen(false)}>Pricing</Link></li>
          <li><Link to="/login" onClick={() => setMenuOpen(false)}>Login</Link></li>
        </ul>
        <div
          className="menu-toggle"
          onClick={handleMenuToggle}
          aria-label="Toggle menu"
          aria-expanded={menuOpen}
          role="button"
        >
          {menuOpen ? <FiX /> : <FiMenu />}
        </div>
      </nav>
    </header>
  );
};

export default Header;
