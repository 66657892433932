import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../components/Sidebar';
import ClientHeader from '../../components/ClientHeader';
import { auth, db } from '../../firebase';
import { collection, query, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import './Cages.css';

const Cages = () => {
  const [cages, setCages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCages, setFilteredCages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [expandedCage, setExpandedCage] = useState(null);
  const cagesPerPage = 20;

  const fetchTotalCagesCount = useCallback(async () => {
    const user = auth.currentUser;
      if (user) {
        const cagesCollection = collection(db, `users/${user.uid}/cages`);
        const totalCagesSnapshot = await getDocs(cagesCollection);
        const totalCagesCount = totalCagesSnapshot.size;
        setTotalPages(Math.ceil(totalCagesCount / cagesPerPage));
      }
  }, [cagesPerPage]);

  const fetchCages = useCallback(async (page = 1) => {
    const user = auth.currentUser;
      if (user) {
        const cagesCollection = collection(db, `users/${user.uid}/cages`);
        let cagesQuery = query(cagesCollection, orderBy('id'), limit(cagesPerPage));
  
        if (page > 1) {
          const previousPageQuery = query(cagesCollection, orderBy('id'), limit((page - 1) * cagesPerPage));
          const previousPageSnapshot = await getDocs(previousPageQuery);
          const lastVisible = previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];
          cagesQuery = query(cagesCollection, orderBy('id'), startAfter(lastVisible), limit(cagesPerPage));
        }

      const cagesSnapshot = await getDocs(cagesQuery);
      const cagesList = cagesSnapshot.docs.map(doc => {
        const data = doc.data();

        // Determine if the cage is active based on the number of birds present in the cage
        data.active = data.bird_ids.length === 2;

        // Check if the last inspection was more than 2 weeks ago (red flag)
        const lastInspectionDate = new Date(data.lastInspectionDate);
        const twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
        data.inspectionWarning = lastInspectionDate < twoWeeksAgo;

        return data;
      });

      setCages(cagesList);
      setFilteredCages(cagesList);
    }
  }, [cagesPerPage]);

  useEffect(() => {
    fetchTotalCagesCount();
    fetchCages(1);
  }, [fetchTotalCagesCount, fetchCages]);

  const toggleCageExpansion = (cageId) => {
    setExpandedCage(expandedCage === cageId ? null : cageId);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchTerm.trim() !== '') {
      const filtered = cages.filter(cage => cage.id.includes(searchTerm));
      setFilteredCages(filtered);
    } 
    else {
      setFilteredCages(cages);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchCages(newPage);
  };

  return (
    <div className="cages-container">
      <ClientHeader />
      <Sidebar />
      <div className="cages-content">
        <h1>Cages Management</h1>
        <p>Here you can manage all your cages.</p>

        <div className="cage-search">
          <form onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="Search by Cage ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button type="submit">Search</button>
          </form>
        </div>

        <div className="cage-table">
          <table>
            <thead>
              <tr>
                <th>Cage</th>
                <th>Active</th>
                <th>Bird IDs</th>
                <th>Chicks</th>
                <th>Cleanliness</th>
                <th>Eggs</th>
                <th>Fatalities</th>
                <th>Fertile Eggs</th>
                <th>Last Inspection Date</th>
                <th>Notes</th>
                <th>Sick Bird ID</th>
              </tr>
            </thead>
            <tbody>
              {filteredCages.length > 0 ? (
                filteredCages.map((cage, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        {cage.id}
                        {cage.inspectionWarning && <span className="inspection-warning" title="Last inspected more than 2 weeks ago">●</span>}
                      </td>
                      <td>{cage.active ? 'Yes' : 'No'}</td>
                      <td>
                        <button onClick={() => toggleCageExpansion(cage.id)}>
                          {expandedCage === cage.id ? 'Hide Birds' : 'View Birds'}
                        </button>
                      </td>
                      <td>{cage.chicks}</td>
                      <td>{cage.cleanliness}</td>
                      <td>{cage.eggs}</td>
                      <td>{cage.fatalities ? cage.fatalities + cage.sickBirdId : 'None'}</td>
                      <td>{cage.fertileEggs}</td>
                      <td>{new Date(cage.lastInspectionDate).toLocaleDateString()}</td>
                      <td>{cage.notes}</td>
                      <td>{cage.sickBirdId}</td>
                    </tr>
                    {expandedCage === cage.id && (
                      <tr>
                        <td colSpan="11">
                          <div className="birds-list">
                            <h4>Birds in Cage {cage.id}:</h4>
                            <ul>
                              {cage.bird_ids.map((birdId, birdIndex) => (
                                <li key={birdIndex}>{birdId}</li>
                              ))}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="11">No cages found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`pagination-button ${page === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cages;

